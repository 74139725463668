<template>
    <div>
        <el-scrollbar>
            <div class="container">
                <div class="content_container user-container">
                    <el-row class="ala-flex">
                        <el-col :xs="24" :md="6">
                            <el-menu
                                    :default-active="nowTab"
                                    class="el-menu-vertical-demo"
                            >
                                <div>
                                    <el-menu-item v-if="programItems.length" v-for="(item,index) in programItems" :index="item.id.toString()"  :key="item.id" @click="changId(item.id)">
                                        <span class="icon">{{index+1}}</span>
                                        <span slot="title" class="title">{{item.name}}</span>
                                    </el-menu-item>
                                </div>
                            </el-menu>
                        </el-col>
                        <el-col :xs="24" :md="18">
                            <div class="user-wrapper">
                                <div class="title-wrap">
                                    <el-breadcrumb separator-class="el-icon-arrow-right">
                                        <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i>
                                        </el-breadcrumb-item>
                                        <el-breadcrumb-item :to="{ path: '/program/'+taskInfo.project_id }">{{taskInfo.name}}</el-breadcrumb-item>
                                        <el-breadcrumb-item>{{taskInfo.name}}</el-breadcrumb-item>
                                    </el-breadcrumb>
                                </div>
                                <div class="user-content">
                                    <div class="intro-list">
                                        <el-row :gutter="20">
                                            <el-col :xs="24" :sm="12">
                                                <div class="intro-item">
                                                    <div class="item-label">任务编号：</div>
                                                    <div class="item-body">{{taskInfo.serial_code}}</div>
                                                </div>
                                            </el-col>
                                            <el-col :xs="24" :sm="12">
                                                <div class="intro-item">
                                                    <div class="item-label">任务状态：</div>
                                                    <div class="item-body">
                                                        <el-tag type="primary" size="small" v-if="taskInfo.status==5">进行中</el-tag>
                                                        <el-tag type="primary" size="small" v-if="taskInfo.status==7">已完成</el-tag>
                                                        <!-- <el-tag type="danger" size="small" effect="dark">已暂停</el-tag> -->
                                                    </div>
                                                </div>
                                            </el-col>
                                            <el-col :xs="24">
                                                <div class="intro-item">
                                                    <div class="item-label">任务详情：</div>
                                                    <div class="item-body">
                                                        {{taskInfo.content}}
                                                    </div>
                                                </div>
                                            </el-col>
                                        </el-row>
                                    </div>
                                    <!-- <hr> -->
                                </div>
                                <div class="user-content">
                                    <div class="task-keyword">
                                        <el-row>
                                            <el-col :xs="8" :sm="8">
                                                <div class="keyword-item">
                                                    <div class="keyword-label">子任务数</div>
                                                    <div class="keyword-body">
                                                        <span>{{taskInfo.children_count}}</span>
                                                    </div>
                                                </div>
                                            </el-col>
                                            <el-col :xs="8" :sm="8">
                                                <div class="keyword-item">
                                                    <div class="keyword-label">任务金额（￥）</div>
                                                    <div class="keyword-body">
                                                        <span>{{taskInfo.money}}</span>
                                                    </div>
                                                </div>
                                            </el-col>
                                            <el-col :xs="8" :sm="8">
                                                <div class="keyword-item">
                                                    <div class="keyword-label">任务进度</div>
                                                    <div class="keyword-body">
                                                        <el-progress
                                                                :stroke-width="3"
                                                                :percentage="taskInfo.final_children_count?Math.floor((taskInfo.final_children_count/taskInfo.children_count)*100):0"
                                                                :color="customColorMethod"
                                                                type="circle"
                                                                :width="48"></el-progress>
                                                    </div>
                                                </div>
                                            </el-col>
                                        </el-row>
                                    </div>
                                </div>

                                <div class="user-content task-list">
                                    <div class="list-head">
                                        <div class="title">子任务列表({{taskItems.length}})</div>
                                        <div class="btn-group">
                                            <div class="btn-group">
                                                <el-radio-group v-model="radio" size="small" v-for="item in tabs" :key="item.id" @change="getTaskDataRadio(item.id)">
                                                    <el-radio-button :label="item.id">{{item.name}}</el-radio-button>
                                                </el-radio-group>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list-body">
                                        <div class="list-item" v-for="item in taskItems" :key='item.id'
                                             @click="toSubTask(item.id)">
                                            <div class="title" style="width:35%">
                                                <span class="label">子任务名称</span>
                                                <span>{{item.name}}</span>
                                            </div>
                                            <div class="code" style="width:15%">
                                                <span class="label">子任务编号</span>
                                                <span>{{item.serial_code}}</span>
                                            </div>
                                            <div class="mode" style="width:20%">
                                                <span class="label">子任务形式</span>
                                                <span v-if="item.type=='video'">视频</span>

                                            </div>
                                            <div class="money" style="width:18%">
                                                <span class="label">子任务金额</span>
                                                <span>￥{{item.money}}</span>
                                            </div>
                                            <div class="progress" style="width:12%">
                                                <span class="label">子任务状态</span>
                                                <span>
                                                  <el-tag type="success" size="mini">{{taskStatusDesc(item.status)}}</el-tag>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </el-scrollbar>
        <!-- 查看子任务 -->
        <el-drawer
                v-if="taskChildrenInfo"
                :visible.sync="subTaskView"
                class="drawer_tc"
                size="40%"
                direction="rtl"
        >
            <div class="drawer-title" slot="title">
                <div class="title">{{taskChildrenInfo.name}}</div>
            </div>
            <el-scrollbar>
                <div class="content_container">
                    <div class="container">
                        <el-descriptions
                                class="margin-top"
                                :column="2"
                                size="medium"
                                border
                        >
                            <el-descriptions-item>
                                <template slot="label"> 任务编号</template>
                                {{taskChildrenInfo.serial_code}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label"> 任务要求</template>
                                {{taskChildrenInfo.task_form}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label"> 制作者</template>
                                {{taskChildrenInfo.taker.name}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label"> 金额</template>
                                ￥{{taskChildrenInfo.money}}

                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label"> 分配时间</template>
                                {{taskChildrenInfo.created_at}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label"> 截止时间</template>
                                {{taskChildrenInfo.expired_at}}
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label"> 任务状态</template>
                                <el-tag type="success" size="mini">{{taskStatusDesc(taskChildrenInfo.status)}}</el-tag>

                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label"> 完成时间</template>
                                {{taskChildrenInfo.finished_at}}
                            </el-descriptions-item>
                            <el-descriptions-item :labelStyle="{ 'vertical-align': 'top' }" :span=2>
                                <template slot="label"> 任务详情</template>
                                <div v-html="taskChildrenInfo.content">
                                </div>
                            </el-descriptions-item>
                        </el-descriptions>

                        <h4 style="margin: 20px 0 10px"><strong>要求完成情况：</strong></h4>
                        <div class="table-list">
                            <el-table :data="taskChildrenInfo.requirement" border>
                                <el-table-column
                                        prop="category"
                                        :show-overflow-tooltip="true"
                                        label="任务分类"
                                        min-width="30%"
                                >
                                    <template slot-scope="scope">
                                        {{scope.row.price_template.duration_code}}：{{scope.row.price_template.category_name}}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        prop="skill"
                                        :show-overflow-tooltip="true"
                                        label="技术要求"
                                        align="center"
                                        width="120"
                                >
                                    <template slot-scope="scope">
                                        {{scope.row.skill}}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        prop="description"
                                        :show-overflow-tooltip="true"
                                        label="内容要求"
                                        align="center"
                                        width="120"
                                >
                                    <template slot-scope="scope">
                                        {{scope.row.description}}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        prop="fileNum"
                                        :show-overflow-tooltip="true"
                                        label="数量"
                                        align="center"
                                        width="80"
                                >
                                    <template slot-scope="scope">
                                        {{scope.row.number}}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        prop="video"
                                        label="视频地址"
                                        min-width="60%"
                                >
                                    <template slot-scope="scope">
                                        <div v-for="item in scope.row.file" :key='item.id'>
                                            <el-link type="primary" target="_blank" @click="videoDetailReview(item)">
                                            <!-- <el-link type="primary" :href="item.path" target="_blank"> -->
                                                <i class="el-icon-video-camera"></i>
                                                <span>{{item.name}}</span>
                                            </el-link>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
                <div class="fixed-bottom" v-if="taskChildrenInfo.status == 11">
                    <el-button type="primary" size="medium" @click="acceptanceReject"><i class="el-icon-circle-check"></i> 驳回</el-button>
                    <el-button type="primary" size="medium" @click="acceptance"><i class="el-icon-circle-check"></i> 确认验收</el-button>
                </div>
            </el-scrollbar>
        </el-drawer>

        <!-- 视频弹窗 -->
        <el-dialog
            :title="videoTitle"
            :visible.sync="videoDetail"
            width="800px"
            class="dialog_tc">
            <video-player  class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="true"
            :options="playerOptions"
            ></video-player>
        </el-dialog>

    </div>
</template>

<script>
    import Api from "@/api";
    import mixinPage from '@/mixins/page'
    export default {
        mixins: [mixinPage],
        data() {
            return {
                tabs:[
                    {
                        id:0,
                        name:'全部'
                    },
                    {
                        id:5,
                        name:'进行中'
                    },
                    {
                        id:7,
                        name:'已完成'
                    }
                ],
                nowTab:'1',
                task_id:'',
                programItems:'',
                project_id:'',
                taskInfo:'',
                taskChildrenInfo:'',
                radio: '0',
                taskItems: [],
                subTaskView: false,
                requirementData2: [],
                quotationView: false, //价目表
                spanArr: [], //用于存放每一行记录的合并数
                taskStatus:{},
                taskProcess:{},
                videoTitle:"",
                videoDetail: false,
                playerOptions : {
                    playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
                    autoplay: false, //如果true,浏览器准备好时开始回放。
                    muted: false, // 默认情况下将会消除任何音频。
                    loop: false, // 导致视频一结束就重新开始。
                    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    language: 'zh-CN',
                    aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [{
                        type: "",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                        src: "" //url地址
                    }],
                    notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    controlBar: {
                        timeDivider: true,
                        durationDisplay: true,
                        remainingTimeDisplay: false,
                        fullscreenToggle: true  //全屏按钮
                    }
                }
            };
        },
        mounted() {
            this.task_id = this.$route.params.id
            this.nowTab = this.$route.params.id
            this.project_id = this.$route.query.project_id
            this.getTaskInfo()
            this.getTaskData()
            this.getTaskChildrenData()

        },
        created(){
            this.getTaskStatus()
        },
        methods: {
            //播放视频弹框
            videoDetailReview(videoUrl){
                if(videoUrl.type==null || videoUrl.type.indexOf('video') == -1){
                    window.open(videoUrl.path)
                    return false
                }else{
                    this.videoTitle = videoUrl.name
                    this.videoDetail = true
                    this.playerOptions.sources[0].src = videoUrl.path
                }

            },
            getTaskStatus(){
                Api.model.custom('/customer/task/taskStatus','get').then(res=>{
                    this.taskStatus = res.status
                    this.taskProcess = res.process
                })
            },
            taskStatusDesc(status){
                return this.taskStatus[status]
            },
            taskProcessDesc(status){
                return this.taskProcess.children[status].next_status
            },
            //验收
            acceptance(){
                let params = {
                    status:13
                }
                Api.model.update('task',params,this.taskChildrenInfo.id).then(res=>{
                    this.success('成功')
                    this.taskChildrenInfo.status = 13
                    this.getTaskChildrenData()
                })
            },
            acceptanceReject(){
                this.$prompt('请输入驳回原因', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputType:'textArea'
                }).then(({ value }) => {
                    Api.model.update('task',{status:14,reason:value},this.taskChildrenInfo.id).then(res=>{
                        this.success('成功')
                        this.taskChildrenInfo.status = 14
                        this.getTaskChildrenData()
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消'
                    });
                });
            },

            changId(task_id){
                this.task_id = task_id
                this.getTaskInfo()
                this.getTaskChildrenData()
            },
            getTaskDataRadio(status){
                this.status = status
                this.getTaskChildrenData()
            },
            //获取主任务下所有子任务
            async getTaskChildrenData(){
                let params = {
                    project_id:this.project_id,
                    parent_id:this.task_id,
                    status:this.radio,
                }
                await Api.model.index('task',params).then(res=>{
                    this.taskItems = res.data
                });
            },
            //获取项目下所有主任务
            async getTaskData(){
                let params = {
                    project_id:this.project_id
                }
                await Api.model.index('task',params).then(res=>{
                    this.programItems = res.data
                });
            },
            //主任务详情
            async getTaskInfo(){
                await Api.model.show('task',this.task_id).then(res=>{
                   this.taskInfo = res
                });
            },
            async toSubTask(task_id) {
                await Api.model.show('task',task_id).then(res=>{
                    this.taskChildrenInfo = res
                    this.subTaskView = true
                });
            },
            customColorMethod(percentage) {
                if (percentage < 30) {
                    return "#F56C6C";
                } else if (percentage < 70) {
                    return "#e6a23c";
                } else {
                    return "#67C23A";
                }
            },

        },
    };
</script>
<style>
    .swal2-container{
        z-index: 3000 !important;
    }
</style>

