<template>
    <el-scrollbar>
        <div class="container">
            <div class="content_container user-container">
                <el-row class="ala-flex">
                    <el-col :xs="24" :md="6">
                        <el-menu
                                :default-active="nowTab"
                                class="el-menu-vertical-demo"
                        >
                            <div>
                                <el-menu-item v-if="programItems.length" v-for="(item,index) in programItems" :index="item.id.toString()"  :key="item.id" @click="changId(item.id)">
                                    <span class="icon">{{index+1}}</span>
                                    <span slot="title" class="title">{{item.name}}</span>
                                </el-menu-item>
                            </div>
                        </el-menu>
                    </el-col>
                    <el-col :xs="24" :md="18">
                        <div class="user-wrapper">
                            <div class="title-wrap">
                                <el-breadcrumb separator-class="el-icon-arrow-right">
                                    <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i>
                                    </el-breadcrumb-item>
                                    <el-breadcrumb-item>{{projectInfo.name}}</el-breadcrumb-item>
                                </el-breadcrumb>
                            </div>
                            <div class="user-content">
                                <div class="intro-list">
                                    <el-row :gutter="20">
                                        <el-col :xs="24" :sm="12">
                                            <div class="intro-item">
                                                <div class="item-label">项目编号：</div>
                                                <div class="item-body">{{projectInfo.code}}</div>
                                            </div>
                                        </el-col>
                                        <el-col :xs="24" :sm="12">
                                            <div class="intro-item">
                                                <div class="item-label">价格表：</div>
                                                <el-popover
                                                        placement="bottom"
                                                        width="600"
                                                        trigger="hover"
                                                >
                                                    <div class="table-list quotation-table">
                                                        <el-table
                                                                :data="quotationData"
                                                                :span-method="objectSpanMethod"
                                                                border
                                                                size="small"
                                                        >
                                                            <el-table-column
                                                                    prop="category_name"
                                                                    :show-overflow-tooltip="true"
                                                                    label="分类"
                                                                    width="120"
                                                            >
                                                            </el-table-column>
                                                            <el-table-column
                                                                    prop="duration"
                                                                    :show-overflow-tooltip="true"
                                                                    label="时长"
                                                                    width="120"
                                                            >
                                                            </el-table-column>
                                                            <el-table-column
                                                                    prop="duration_code"
                                                                    :show-overflow-tooltip="true"
                                                                    label="时长编码"
                                                                    align="center"
                                                            >
                                                            </el-table-column>
                                                            <el-table-column
                                                                    prop="price"
                                                                    label="报价"
                                                                    align="center"
                                                            >
                                                            </el-table-column>
                                                            <el-table-column
                                                                    prop="rate"
                                                                    label="扣率%"
                                                                    align="center"
                                                            >
                                                            </el-table-column>
                                                            <el-table-column
                                                                    prop="finalPrice"
                                                                    label="税后价"
                                                                    align="center"
                                                            >
                                                                <template slot-scope="scope">
                                                                    {{
                                                                    (scope.row.price *
                                                                    (100 - scope.row.rate)) /
                                                                    100
                                                                    }}
                                                                </template>
                                                            </el-table-column>
                                                        </el-table>
                                                    </div>
                                                    <span slot="reference"
                                                    >查看</span>
                                                </el-popover>
                                            </div>
                                        </el-col>
                                        <el-col :xs="24" :sm="12">
                                            <div class="intro-item">
                                                <div class="item-label">有效期：</div>
                                                <div class="item-body">{{projectInfo.created_at}} ~ {{projectInfo.expire_at}}</div>
                                            </div>
                                        </el-col>
                                        <el-col :xs="24" :sm="12">
                                            <div class="intro-item">
                                                <div class="item-label">负责人：</div>
                                                <div class="item-body">{{projectInfo.user?projectInfo.user.name:''}}</div>
                                            </div>
                                        </el-col>
                                        <el-col :xs="24" :sm="12">
                                            <div class="intro-item">
                                                <div class="item-label">项目状态：</div>
                                                <div class="item-body">
                                                    <el-tag type="primary" size="small" v-if="projectInfo.status==1">进行中</el-tag>
                                                    <el-tag type="primary" size="small" v-else-if="projectInfo.status==2">已完成</el-tag>
                                                    <el-tag type="primary" size="small" v-else-if="projectInfo.status==3">已暂停</el-tag>
                                                    <!-- <el-tag type="danger" size="small" effect="dark">已暂停</el-tag> -->
                                                </div>
                                            </div>
                                        </el-col>
                                        <el-col :xs="24">
                                            <div class="intro-item">
                                                <div class="item-label">项目详情：</div>
                                                <div class="item-body" v-html="projectInfo.content">
                                                </div>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>
                                <!-- <hr> -->
                            </div>
                            <div class="user-content">
                                <div class="task-keyword">
                                    <el-row>
                                        <el-col :xs="8" :sm="8">
                                            <div class="keyword-item">
                                                <div class="keyword-label">主任务数</div>
                                                <div class="keyword-body">
                                                    <span>{{projectInfo.task_count}}</span>
                                                </div>
                                            </div>
                                        </el-col>
                                        <el-col :xs="8" :sm="8">
                                            <div class="keyword-item">
                                                <div class="keyword-label">项目总金额（￥）</div>
                                                <div class="keyword-body">
                                                    <span>{{projectInfo.moneyTotal}}</span>
                                                </div>
                                            </div>
                                        </el-col>
                                        <el-col :xs="8" :sm="8">
                                            <div class="keyword-item">
                                                <div class="keyword-label">项目总进度</div>
                                                <div class="keyword-body">
                                                    <el-progress
                                                            :stroke-width="3"
                                                            :percentage="projectInfo.final_task_children_count?Math.floor((projectInfo.final_task_children_count/projectInfo.task_children_count)*100):0"
                                                            :color="customColorMethod"
                                                            type="circle"
                                                            :width="48"></el-progress>
                                                </div>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>

                            <div class="user-content task-list">
                                <div class="list-head">
                                    <div class="title">任务列表({{taskItems.length}})</div>
                                    <div class="btn-group">
                                        <el-radio-group v-model="radio" size="small" v-for="item in tabs" :key="item.id" @change="getTaskDataRadio(item.id)">
                                            <el-radio-button :label="item.id">{{item.name}}</el-radio-button>
                                        </el-radio-group>
                                    </div>
                                </div>
                                <div class="list-body">
                                    <div class="list-item" v-for="item in taskItems" :key='item.id'
                                         @click="toTaskView(item.id)">
                                        <div class="title" style="width:35%">
                                            <span class="label">任务名称</span>
                                            <span>{{item.name}}</span>
                                        </div>
                                        <div class="code" style="width:15%">
                                            <span class="label">任务编号</span>
                                            <span>{{item.serial_code}}</span>
                                        </div>
                                        <div class="subtask" style="width:12%">
                                            <span class="label">子任务数</span>
                                            <span>{{item.children_count}}</span>
                                        </div>
                                        <div class="money" style="width:18%">
                                            <span class="label">任务金额</span>
                                            <span>￥{{item.money}}</span>
                                        </div>
                                        <div class="progress" style="width:20%">
                                            <span class="label">任务进度</span>
                                            <span>
                                                <el-progress
                                                        :stroke-width="6"
                                                        :percentage="item.final_children_count?Math.floor((item.final_children_count/item.children_count)*100):0"
                                                        :color="customColorMethod"></el-progress>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
    </el-scrollbar>
</template>

<script>

    import Api from '@/api';
    export default {

        data() {
            return {
                nowTab:'1',
                id:'',
                radio: '0',
                tabs:[
                    {
                        id:0,
                        name:'全部'
                    },
                    {
                        id:5,
                        name:'进行中'
                    },
                    {
                        id:7,
                        name:'已完成'
                    }
                ],
                status:0,
                projectInfo:'',
                taskItems: [],
                programItems:'',
                quotationData:[],
                spanArr: [], //用于存放每一行记录的合并数
            };
        },
        created(){
            this.id = this.$route.params.id
            this.nowTab = this.$route.params.id
            this.getProjectList()
            this.getProjectInfo()
            this.getTaskData()
            this.getPriceTemplate()
        },
        methods: {
            async getPriceTemplate(){
                await Api.model.custom('/customer/task/priceTemplate').then(res=>{
                    this.quotationData = res
                    console.log( this.quotationData)
                    this.getSpanArr(this.quotationData);
                });
            },
            getSpanArr(data) {
                // data就是我们从后台拿到的数据
                for (var i = 0; i < data.length; i++) {
                    if (i === 0) {
                        this.spanArr.push(1);
                        this.pos = 0;
                    } else {
                        // 判断当前元素与上一个元素是否相同
                        if (data[i].category_name === data[i - 1].category_name) {
                            this.spanArr[this.pos] += 1;
                            this.spanArr.push(0);
                        } else {
                            this.spanArr.push(1);
                            this.pos = i;
                        }
                    }
                    // console.log(this.spanArr);
                    // console.log(this.pos);
                }
            },
            objectSpanMethod({rowIndex, columnIndex}) {
                if (columnIndex === 0) {
                    const _row = this.spanArr[rowIndex];
                    const _col = _row > 0 ? 1 : 0;
                    // console.log(`rowspan:${_row} colspan:${_col}`);
                    return {
                        // [0,0] 表示这一行不显示， [2,1]表示行的合并数
                        rowspan: _row,
                        colspan: _col,
                    };
                }
            },
            changId(id){
                this.id = id
                this.getProjectInfo()
                this.getTaskData()
            },
            async getProjectList(){
                await Api.model.index('project').then(res=>{
                    this.programItems = res
                });
            },
            getTaskDataRadio(status){
                this.status = status
                this.getTaskData()
            },
            async getTaskData(){
                let params = {
                    status:this.status,
                    project_id:this.id
                }
                await Api.model.index('task',params).then(res=>{
                    this.taskItems = res.data
                });
            },
            async getProjectInfo(){
                await Api.model.show('project',this.id).then(res=>{
                    this.projectInfo = res
                    console.log(res)
                });
            },
            toTaskView(id) {
                this.$router.push({path:`/task/${id}`,query:{project_id:this.id}});
            },
            customColorMethod(percentage) {
                if (percentage < 30) {
                    return "#F56C6C";
                } else if (percentage < 70) {
                    return "#e6a23c";
                } else {
                    return "#67C23A";
                }
            }
        },
    };
</script>
