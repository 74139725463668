<template>
    <el-scrollbar>
        <div class="container">
            <div class="content_container">
                <div class="program-list">
                    <el-row :gutter="20" class="ala-flex">
                        <el-col :sm="24" :md="12" v-for="item in programItems" :key='item.id'
                                @click.native="toProgramView(item.id)">
                            <el-card class="program-item">
                                <div slot="header" class="item-head">
                                    <span class="title">{{item.name}}</span>
                                    <span class="status" v-if="item.status==1">进行中</span>
                                    <span class="status" v-else-if="item.status==2" style="background-color: #F56C6C">已完成</span>
                                </div>
                                <div class="item-body">
                                    <div class="desc" v-html="item.content">

                                    </div>
                                </div>
                                <div class="item-bottom">
                                    <div class="left">
                                        <span>项目编号：{{item.code}}</span>
                                        <span>有效期：{{item.expire_at}}</span>
                                    </div>
                                    <div class="right">
                                        <el-row :gutter="10" class="ala-flex">
                                            <el-col :span='8'>
                                                <div class="btn">
                                                    <i class="el-icon-s-data"></i>
                                                    <div class="title">
                                                        {{item.task_count}}个
                                                    </div>
                                                    <div class="desc">
                                                        任务数量
                                                    </div>
                                                </div>
                                            </el-col>
                                            <el-col :span='8'>
                                                <div class="btn">
                                                    <i class="el-icon-s-custom"></i>
                                                    <div class="title">
                                                        {{item.user?item.user.name:''}}
                                                    </div>
                                                    <div class="desc">
                                                        负责人
                                                    </div>
                                                </div>
                                            </el-col>
                                            <el-col :span='8'>
                                                <div class="btn">
                                                    <el-progress
                                                            :stroke-width="4"
                                                            :percentage="item.final_task_children_count?Math.floor((item.final_task_children_count/item.task_children_count)*100):0"
                                                            :color="customColorMethod"
                                                            type="circle"
                                                            :width="56"></el-progress>
                                                </div>
                                            </el-col>
                                        </el-row>
                                    </div>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </el-scrollbar>
</template>

<script>
    import Api from '@/api'
    export default {
        data() {
            return {
                programItems: []
            }
        },
        created() {
            this.getProjectList()
        },
        methods: {
            async getProjectList(){
                await Api.model.index('project').then(res=>{
                    this.programItems = res
                });
            },
            toProgramView(id) {
                this.$router.push(`/program/${id}`);
            },
            customColorMethod(percentage) {
                if (percentage < 30) {
                    return '#F56C6C';
                } else if (percentage < 70) {
                    return '#e6a23c';
                } else {
                    return '#67C23A';
                }
            },
        }
    }
</script>
