<template>
    <div class="login">
        <div class="login-container">
            <div class="login-left">
                <img src="../assets/images/login-img.png" alt=""/>
            </div>
            <div class="login-wrapper">
                <div class="login-title">
                    <img src="../assets/images/logo.png" class="logo" alt="">
                </div>
                <el-form :model="loginForm" status-icon :rules="rules" ref="loginForm">
                    <el-form-item prop="phone">
                        <el-input
                                type="text"
                                v-model="loginForm.phone"
                                placeholder="请输入手机号"
                        >
                            <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
                        </el-input>
                    </el-form-item>
                    <el-form-item class="sms-code" prop="smsCode">
                        <el-input
                                type="text"
                                v-model="loginForm.smsCode"
                                placeholder="请输入验证码"
                        >
                            <i
                                    slot="prefix"
                                    class="el-input__icon el-icon-chat-dot-round"
                            ></i>
                        </el-input>
                        <el-button type="text" v-if="show" @click="getCode">发送验证码</el-button>
                         <el-button type="text" v-if="!show">{{ times }}</el-button>
                    </el-form-item>
                    <el-form-item class="submit-button">
                        <el-button type="primary" @click="submitForm('loginForm')"
                        >立即登录
                        </el-button
                        >
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>

    import { mapActions } from 'vuex'
    import mixinPage from '@/mixins/page'
    import Api from '@/api'
    export default {
        mixins: [mixinPage],
        data() {
            var isPhone = (rule, value, callback) => {
                const reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
                if (!value) {
                    this.warning("请输入手机号")
                }
                setTimeout(() => {
                    if (!reg.test(value) && value != "") {
                        this.warning("手机格式不正确")
                    } else {
                        callback();
                    }
                }, 1000);
            };
            return {
                times: 60,
                show:true,
                loginForm: {
                    phone: "",
                    smsCode: ''
                },
                rules: {
                    phone: [{validator: isPhone, trigger: "blur"}],
                    smsCode: [
                        {required: true, message: "验证码不能为空", trigger: "blur"}
                    ],
                }
            };
        },
        methods: {
            ...mapActions('admin/account',['login']),
            //发送验证码
            getCode() {
                const reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
                if(this.loginForm.phone==''){
                    this.warning("请输入手机号")
                }else if(!reg.test(this.loginForm.phone)){
                    this.warning("手机格式不正确")
                }else{
                    this.show = false
                    this.timer = setInterval(()=>{
                        this.times--
                        if(this.times===0){
                            this.show = true
                            this.times = 60
                            clearInterval(this.timer)
                        }
                    },1000)
                    let params = {
                        mobile:this.loginForm.phone
                    }
                    console.log(params)
                    Api.model.custom('/customer/login/sendCaptcha?mobile='+this.loginForm.phone,'get').then(res=>{
                        console.log(res)
                    })
                }
            },
            submitForm(formName) {
                this.$refs[formName].validate(async valid => {
                    if (valid) {
                        await this.login({
                            username: this.loginForm.phone,
                            captcha: this.loginForm.smsCode
                        });
                        setTimeout(() => {
                            this.$router.replace(this.$route.query.redirect || '/');
                        });
                    } else {
                        console.log("error submit!!");
                        return false;
                    }
                });
            }
        }
    };
</script>
<style scoped>
    @import "../assets/css/login.css";
</style>
